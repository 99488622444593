import React from 'react';

 

class App extends React.Component {

    render(){

 

        return (



<>
  <meta charSet="UTF-8" />
  <title>CLBcloud</title>
  <meta name="referrer" content="origin-when-cross-origin" />
  <link rel="canonical" href="https://www.clbcloud.com/home.html" />
  <meta
    name="viewport"
    content="width=device-width,initial-scale=1,shrink-to-fit=no"
  />
  <style dangerouslySetInnerHTML={{ __html: ".anim{visibility:hidden}" }} />
  <link rel="shortcut icon" type="image/x-icon" href="favicon.ico" />
  <meta
    name="msapplication-TileImage"
    content="images/mstile-144x144-536e83.png"
  />
  <meta name="keywords" content="CLB ElementEnergy" />
  <meta name="author" content="Jade Kwon" />
  <link rel="stylesheet" href="css/site.45ecb8.css" type="text/css" />
  {/*[if lte IE 7]>
<link rel="stylesheet" href="css/site.45ecb8-lteIE7.css" type="text/css">
<![endif]*/}
  {/*[if lte IE 8]>
<link rel="stylesheet" href="css/site.45ecb8-lteIE8.css" type="text/css">
<![endif]*/}
  {/*[if gte IE 9]>
<link rel="stylesheet" href="css/site.45ecb8-gteIE9.css" type="text/css">
<![endif]*/}
  <div className="v1 ps1 s1 c1">
    <div className="ps2"></div>
    <div className="v2 ps3 s2 c2">
      <div className="ps2"></div>
      <div className="v1 ps1 s2 c3">
        <div className="ps1 v2 s3">
          <div className="v3 ps4 s4 c4">
            <ul className="menu-dropdown v4 ps5 s4 m1" id="m1">
              <li className="v1 ps5 s5 mit1">
                <a href="#" className="ml1">
                  <div className="menu-content mcv1">
                    <div className="v1 ps5 s6 c5">
                      <div className="v1 ps6 s7 c6">
                        <p className="p1 f1">Home</p>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
              <li className="v1 ps7 s8 mit1">
                <div className="menu-content mcv1">
                  <div className="v1 ps5 s9 c7">
                    <div className="v1 ps6 s10 c6">
                      <p className="p1 f1">Cell level metrics</p>
                    </div>
                  </div>
                </div>
                <ul className="menu-dropdown v5 ps5 s11 m1">
                  <li className="v1 ps5 s12 mit1">
                    <a href="portal.html" className="ml1">
                      <div className="menu-content mcv1">
                        <div className="v1 ps5 s13 c8">
                          <div className="v1 ps8 s14 c6">
                            <p className="p1 f1">Portal</p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="v1 ps9 s12 mit1">
                    <a href="ee-clb-test.html" className="ml1">
                      <div className="menu-content mcv1">
                        <div className="v1 ps5 s13 c9">
                          <div className="v1 ps8 s14 c6">
                            <p className="p1 f1">EE CLB Test</p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="v1 ps9 s12 mit1">
                    <a href="m26.html" className="ml1">
                      <div className="menu-content mcv1">
                        <div className="v1 ps5 s13 c10">
                          <div className="v1 ps8 s14 c6">
                            <p className="p1 f1">M26</p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </li>
              <li className="v1 ps7 s15 mit1">
                <div className="menu-content mcv1">
                  <div className="v1 ps5 s16 c11">
                    <div className="v1 ps6 s17 c6">
                      <p className="p1 f1">Prognostic Diagnosis</p>
                    </div>
                  </div>
                </div>
                <ul className="menu-dropdown v5 ps5 s11 m1">
                  <li className="v1 ps5 s12 mit1">
                    <a href="#" className="ml1">
                      <div className="menu-content mcv1">
                        <div className="v1 ps5 s13 c12">
                          <div className="v1 ps8 s14 c6">
                            <p className="p1 f1">Item</p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="v1 ps9 s12 mit1">
                    <a href="#" className="ml1">
                      <div className="menu-content mcv1">
                        <div className="v1 ps5 s13 c13">
                          <div className="v1 ps8 s14 c6">
                            <p className="p1 f1">Item</p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="v1 ps9 s12 mit1">
                    <a href="#" className="ml1">
                      <div className="menu-content mcv1">
                        <div className="v1 ps5 s13 c14">
                          <div className="v1 ps8 s14 c6">
                            <p className="p1 f1">Item</p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </li>
              <li className="v1 ps7 s18 mit1">
                <div className="menu-content mcv1">
                  <div className="v1 ps5 s19 c15">
                    <div className="v1 ps6 s20 c6">
                      <p className="p1 f1">O&amp;M</p>
                    </div>
                  </div>
                </div>
                <ul className="menu-dropdown v5 ps5 s11 m1">
                  <li className="v1 ps5 s12 mit1">
                    <a href="aws.html" className="ml1">
                      <div className="menu-content mcv1">
                        <div className="v1 ps5 s13 c16">
                          <div className="v1 ps8 s14 c6">
                            <p className="p1 f1">AWS</p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="v1 ps9 s12 mit1">
                    <a href="influxdb.html" className="ml1">
                      <div className="menu-content mcv1">
                        <div className="v1 ps5 s13 c17">
                          <div className="v1 ps8 s14 c6">
                            <p className="p1 f1">InfluxDB</p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="v1 ps9 s12 mit1">
                    <a href="mqtt.html" className="ml1">
                      <div className="menu-content mcv1">
                        <div className="v1 ps5 s13 c18">
                          <div className="v1 ps8 s14 c6">
                            <p className="p1 f1">IoT Core Raw</p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </li>
              <li className="v1 ps7 s21 mit1">
                <div className="menu-content mcv1">
                  <div className="v1 ps5 s22 c19">
                    <div className="v1 ps6 s23 c6">
                      <p className="p1 f1">ML OPS</p>
                    </div>
                  </div>
                </div>
                <ul className="menu-dropdown v5 ps5 s24 m1">
                  <li className="v1 ps5 s25 mit1">
                    <a
                      href="https://cloud.h2o.ai/"
                      target="_blank"
                      rel="noopener"
                      className="ml1"
                    >
                      <div className="menu-content mcv1">
                        <div className="v1 ps5 s26 c20">
                          <div className="v1 ps8 s27 c6">
                            <p className="p1 f1">H2o</p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="v1 ps9 s25 mit1">
                    <a
                      href="https://mlflow.org"
                      target="_blank"
                      rel="noopener"
                      className="ml1"
                    >
                      <div className="menu-content mcv1">
                        <div className="v1 ps5 s26 c21">
                          <div className="v1 ps8 s27 c6">
                            <p className="p1 f1">MLFlow</p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="v1 ps9 s25 mit1">
                    <a href="#" className="ml1">
                      <div className="menu-content mcv1">
                        <div className="v1 ps5 s26 c22">
                          <div className="v1 ps8 s27 c6">
                            <p className="p1 f1">Item</p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="fbk v1 ps10 s28 c23 fx1" data-fbk="fx1">
    <div className="ps11 v2 s29">
      <div className="v1 ps12 s30 c2">
        <div className="v1 ps13 s31 w1">
          <div className="v1 ps14 s32 c24">
            <p className="p1 f2">CLB CLOUD</p>
          </div>
          <div className="v1 ps15 s33 c25">
            <h2 className="p2 f3">
              CLBcloud cell level battery management is a digital twin of
              battery management system which provides SOX features from wide
              variety of data passed thru the cloud
            </h2>
          </div>
          <div className="v1 ps16 s34 c26">
            <a href="#" className="f4 btn1 v6 s35">
              DISCOVER
            </a>
          </div>
        </div>
        <div className="v1 ps17 s36 c27">
          <div className="js1 v1 ps13 s37 c28 ga1">
            <div className="slider">
              <div>
                <picture className="s38">
                  <source
                    srcSet="images/picture3-235-1.webp 1x, images/picture3-470-1.webp 2x"
                    type="image/webp"
                    media="(max-width:479px)"
                  />
                  <source
                    srcSet="images/picture3-235-1.png 1x, images/picture3-470-1.png 2x"
                    media="(max-width:479px)"
                  />
                  <source
                    srcSet="images/picture3-352-1.webp 1x, images/picture3-704-1.webp 2x"
                    type="image/webp"
                    media="(max-width:767px)"
                  />
                  <source
                    srcSet="images/picture3-352-1.png 1x, images/picture3-704-1.png 2x"
                    media="(max-width:767px)"
                  />
                  <source
                    srcSet="images/picture3-564-1.webp 1x, images/picture3-1128-1.webp 2x"
                    type="image/webp"
                    media="(max-width:959px)"
                  />
                  <source
                    srcSet="images/picture3-564-1.png 1x, images/picture3-1128-1.png 2x"
                    media="(max-width:959px)"
                  />
                  <source
                    srcSet="images/picture3-705-1.webp 1x, images/picture3-1410-1.webp 2x"
                    type="image/webp"
                    media="(max-width:1199px)"
                  />
                  <source
                    srcSet="images/picture3-705-1.png 1x, images/picture3-1410-1.png 2x"
                    media="(max-width:1199px)"
                  />
                  <source
                    srcSet="images/picture3-881-1.webp 1x, images/picture3-1762-1.webp 2x"
                    type="image/webp"
                    media="(min-width:1200px)"
                  />
                  <source
                    srcSet="images/picture3-881-1.png 1x, images/picture3-1762-1.png 2x"
                    media="(min-width:1200px)"
                  />
                  <img
                    className="slide0 s39"
                    src="images/picture3-235-1.png"
                    alt="overviews of clbcloud"
                  />
                </picture>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a name="clblive" className="v2 ps18 s40" />
  </div>
  <div className="v2 ps3 s41 c29">
    <div className="ps2"></div>
    <div className="v1 ps1 s42 c30" />
    <div className="ps19 v2 s43">
      <div className="v3 ps5 s44 c31">
        <ul className="menu-dropdown-1 v4 ps5 s44 m1" id="m2">
          <li className="v1 ps5 s45 mit1">
            <a href="#" className="ml1">
              <div className="menu-content mcv1">
                <div className="v1 ps5 s46 c32">
                  <div className="v1 ps20 s47 c33">
                    <p className="p1 f5">CLB LIVE</p>
                  </div>
                </div>
              </div>
            </a>
          </li>
          <li className="v1 ps7 s45 mit1">
            <a href="#" className="ml1">
              <div className="menu-content mcv1">
                <div className="v1 ps5 s46 c34">
                  <div className="v1 ps20 s47 c33">
                    <p className="p1 f5">DATA FLOW</p>
                  </div>
                </div>
              </div>
            </a>
          </li>
          <li className="v1 ps7 s45 mit1">
            <a href="#" className="ml1">
              <div className="menu-content mcv1">
                <div className="v1 ps5 s46 c35">
                  <div className="v1 ps20 s47 c33">
                    <p className="p1 f5">WORK</p>
                  </div>
                </div>
              </div>
            </a>
          </li>
          <li className="v1 ps7 s45 mit1">
            <a href="#" className="ml1">
              <div className="menu-content mcv1">
                <div className="v1 ps5 s46 c36">
                  <div className="v1 ps20 s47 c33">
                    <p className="p1 f5">CONSIDERATIONS</p>
                  </div>
                </div>
              </div>
            </a>
          </li>
          <li className="v1 ps7 s45 mit1">
            <a href="#" className="ml1">
              <div className="menu-content mcv1">
                <div className="v1 ps5 s46 c37">
                  <div className="v1 ps20 s47 c33">
                    <p className="p1 f5">LINKS</p>
                  </div>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div className="ps21 v2 s48">
    <div className="v1 ps22 s49 c38">
      <h1 className="p2 f6">CLB live status</h1>
      <h2 className="p2 f7">
        This shows a live graph of our test system running&nbsp;
      </h2>
    </div>
    <div className="v1 ps23 s50 c39">
      <p className="p1 f8">UCSD project</p>
    </div>
    <div className="v1 ps24 s51 c2">
      <div className="v1 ps5 s52 c40">
        <div className="wrapper1">
          <iframe
            frameBorder={0}
            src="https://grafana.clbcloud.com/d-solo/KOBrehGnk/ucsd-for-clbcloud?orgId=1&theme=light&panelId=2"
            width="100%"
            height="100%"
          />
        </div>
      </div>
      <div className="v1 ps25 s53 c41">
        <div className="wrapper2">
          <iframe
            frameBorder={0}
            src="https://grafana.clbcloud.com/d-solo/KOBrehGnk/ucsd-for-clbcloud?orgId=1&theme=light&panelId=3"
            width="100%"
            height="100%"
          />
        </div>
      </div>
    </div>
    <div className="v1 ps26 s54 c2">
      <div className="v1 ps5 s55 c42">
        <div className="wrapper3">
          <iframe
            frameBorder={0}
            src="https://grafana.clbcloud.com/d-solo/KOBrehGnk/ucsd-for-clbcloud?orgId=1&theme=light&panelId=5"
            width="100%"
            height="100%"
          />
        </div>
      </div>
      <div className="v1 ps27 s56 c43">
        <div className="wrapper4">
          <iframe
            frameBorder={0}
            src="https://grafana.clbcloud.com/d-solo/KOBrehGnk/ucsd-for-clbcloud?orgId=1&theme=light&panelId=4"
            width="100%"
            height="100%"
          />
        </div>
      </div>
    </div>
    <div className="v1 ps28 s57 c44">
      <p className="p1 f8">Element Energy Pangyo</p>
    </div>
    <div className="v1 ps29 s58 c45">
      <div className="v1 ps5 s59 c2">
        <div className="v1 ps30 s60 c46">
          <div className="wrapper5">
            <iframe
              frameBorder={0}
              src="https://grafana.clbcloud.net/d-solo/ZFzTRFeMk/clbnethome?orgId=1&theme=light&panelId=2&refresh=1m"
              width="100%"
              height="100%"
            />
          </div>
        </div>
        <div className="v1 ps31 s61 c46">
          <div className="wrapper6">
            <iframe
              frameBorder={0}
              src="https://grafana.clbcloud.net/d-solo/iwCYGK9Gk/eek-test?orgId=1&var-ibatt=All&var-vbatt=All&var-vNode=All&var-npstemp=All&var-nodespc=All&var-nodespt=All&var-tbatt=All&var-topic=All&var-myrunid=All&var-Nodes=All&refresh=1m&theme=light&panelId=589"
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </div>
      <div className="v1 ps32 s62 c2">
        <div className="v1 ps33 s63 c46">
          <div className="wrapper7">
            <iframe
              frameBorder={0}
              src="https://grafana.clbcloud.net/d-solo/iwCYGK9Gk/eek-test?orgId=1&refresh=1m&theme=light&panelId=448"
              width="100%"
              height="100%"
            />
          </div>
        </div>
        <div className="v1 ps34 s64 c46">
          <div className="wrapper8">
            <iframe
              frameBorder={0}
              src="https://grafana.clbcloud.com/d-solo/iwCYGK9Gk/eek-test?orgId=1&refresh=5m&theme=light&panelId=614"
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </div>
      <div className="v1 ps35 s65 c2">
        <div className="v1 ps36 s66 c46">
          <div className="wrapper9">
            <iframe
              frameBorder={0}
              src="https://grafana.clbcloud.com/d-solo/iwCYGK9Gk/eek-test?orgId=1&refresh=5m&theme=light&panelId=603"
              width="100%"
              height="100%"
            />
          </div>
        </div>
        <div className="v1 ps37 s67 c46">
          <div className="wrapper10">
            <iframe
              frameBorder={0}
              src="https://grafana.clbcloud.net/d-solo/iwCYGK9Gk/eek-test?orgId=1&refresh=1m&theme=light&panelId=596"
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <a name="team" className="v2 ps38 s40" />
  <div className="v1 ps39 s68 c47">
    <div className="v1 ps1 s68 c48">
      <div className="c49 s69"></div>
      <div className="ps40 v2 s70">
        <div className="anim fadeInLeft js2 v1 ps22 s71 c50">
          <h1 className="p2 f9">Data flow</h1>
          <h2 className="p2 f7">
            To run a model all data has to run fluently and also this data
            should be feedback to the system for better SOX measures
          </h2>
        </div>
      </div>
    </div>
  </div>
  <div className="c51">
    <div className="ps1 v2 s72">
      <div className="v1 ps5 s73 c52">
        <div className="v1 ps41 s74 c2">
          <div className="v1 ps42 s75 w1">
            <div className="anim fadeInUp js4 v1 ps5 s76 c53">
              <picture className="i2 js261">
                <source
                  data-srcset="images/down-arrow-70-1.webp 1x, images/down-arrow-140-1.webp 2x"
                  type="image/webp"
                  media="(max-width:479px)"
                />
                <source
                  data-srcset="images/down-arrow-70-1.png 1x, images/down-arrow-140-1.png 2x"
                  media="(max-width:479px)"
                />
                <source
                  data-srcset="images/down-arrow-105-1.webp 1x, images/down-arrow-210-2.webp 2x"
                  type="image/webp"
                  media="(max-width:767px)"
                />
                <source
                  data-srcset="images/down-arrow-105-1.png 1x, images/down-arrow-210-2.png 2x"
                  media="(max-width:767px)"
                />
                <source
                  data-srcset="images/down-arrow-168-1.webp 1x, images/down-arrow-336-1.webp 2x"
                  type="image/webp"
                  media="(max-width:959px)"
                />
                <source
                  data-srcset="images/down-arrow-168-1.png 1x, images/down-arrow-336-1.png 2x"
                  media="(max-width:959px)"
                />
                <source
                  data-srcset="images/down-arrow-210-3.webp 1x, images/down-arrow-420-1.webp 2x"
                  type="image/webp"
                  media="(max-width:1199px)"
                />
                <source
                  data-srcset="images/down-arrow-210-3.png 1x, images/down-arrow-420-1.png 2x"
                  media="(max-width:1199px)"
                />
                <source
                  data-srcset="images/down-arrow-263.webp 1x"
                  type="image/webp"
                  media="(min-width:1200px)"
                />
                <source
                  data-srcset="images/down-arrow-263.png 1x"
                  media="(min-width:1200px)"
                />
                <img data-src="images/down-arrow-70-1.png" className="js3 i1" />
              </picture>
            </div>
            <div className="v1 ps43 s77 c54">
              <p className="p2 f10">Data in</p>
            </div>
          </div>
          <div className="anim fadeInUp js6 v1 ps44 s78 c46">
            <picture className="i2 js262">
              <source
                data-srcset="images/clbpack-54.webp 1x, images/clbpack-108.webp 2x"
                type="image/webp"
                media="(max-width:479px)"
              />
              <source
                data-srcset="images/clbpack-54.png 1x, images/clbpack-108.png 2x"
                media="(max-width:479px)"
              />
              <source
                data-srcset="images/clbpack-81.webp 1x, images/clbpack-162.webp 2x"
                type="image/webp"
                media="(max-width:767px)"
              />
              <source
                data-srcset="images/clbpack-81.png 1x, images/clbpack-162.png 2x"
                media="(max-width:767px)"
              />
              <source
                data-srcset="images/clbpack-131.webp 1x, images/clbpack-262.webp 2x"
                type="image/webp"
                media="(max-width:959px)"
              />
              <source
                data-srcset="images/clbpack-131.png 1x, images/clbpack-262.png 2x"
                media="(max-width:959px)"
              />
              <source
                data-srcset="images/clbpack-163.webp 1x"
                type="image/webp"
                media="(max-width:1199px)"
              />
              <source
                data-srcset="images/clbpack-163.png 1x"
                media="(max-width:1199px)"
              />
              <source
                data-srcset="images/clbpack-204.webp 1x"
                type="image/webp"
                media="(min-width:1200px)"
              />
              <source
                data-srcset="images/clbpack-204.png 1x"
                media="(min-width:1200px)"
              />
              <img
                data-src="images/clbpack-54.png"
                alt="CLB battery"
                className="js5 i3"
              />
            </picture>
          </div>
          <div className="v1 ps45 s79 w1">
            <div className="anim fadeInUp js8 v1 ps5 s80 c55">
              <picture className="i2 js263">
                <source
                  data-srcset="images/uparrow-89.webp 1x, images/uparrow-178.webp 2x"
                  type="image/webp"
                  media="(max-width:479px)"
                />
                <source
                  data-srcset="images/uparrow-89.png 1x, images/uparrow-178.png 2x"
                  media="(max-width:479px)"
                />
                <source
                  data-srcset="images/uparrow-132.webp 1x, images/uparrow-264.webp 2x"
                  type="image/webp"
                  media="(max-width:767px)"
                />
                <source
                  data-srcset="images/uparrow-132.png 1x, images/uparrow-264.png 2x"
                  media="(max-width:767px)"
                />
                <source
                  data-srcset="images/uparrow-212.webp 1x, images/uparrow-424.webp 2x"
                  type="image/webp"
                  media="(max-width:959px)"
                />
                <source
                  data-srcset="images/uparrow-212.png 1x, images/uparrow-424.png 2x"
                  media="(max-width:959px)"
                />
                <source
                  data-srcset="images/uparrow-265.webp 1x"
                  type="image/webp"
                  media="(max-width:1199px)"
                />
                <source
                  data-srcset="images/uparrow-265.png 1x"
                  media="(max-width:1199px)"
                />
                <source
                  data-srcset="images/uparrow-331.webp 1x"
                  type="image/webp"
                  media="(min-width:1200px)"
                />
                <source
                  data-srcset="images/uparrow-331.png 1x"
                  media="(min-width:1200px)"
                />
                <img data-src="images/uparrow-89.png" className="js7 i4" />
              </picture>
            </div>
            <div className="v1 ps46 s81 c56">
              <p className="p2 f10">OTA update&nbsp;</p>
            </div>
          </div>
        </div>
        <div className="v1 ps5 s82 c2">
          <div className="anim fadeInUp js10 v1 ps47 s83 c57">
            <a
              onclick="sparklePopup && sparklePopup.openPopup('popup1');return 0"
              style={{ cursor: "pointer" }}
              className="a1"
            >
              <picture className="i2 js264">
                <source
                  data-srcset="images/mqtt-65.webp 1x, images/mqtt-130.webp 2x"
                  type="image/webp"
                  media="(max-width:479px)"
                />
                <source
                  data-srcset="images/mqtt-65.png 1x, images/mqtt-130.png 2x"
                  media="(max-width:479px)"
                />
                <source
                  data-srcset="images/mqtt-98.webp 1x, images/mqtt-196.webp 2x"
                  type="image/webp"
                  media="(max-width:767px)"
                />
                <source
                  data-srcset="images/mqtt-98.png 1x, images/mqtt-196.png 2x"
                  media="(max-width:767px)"
                />
                <source
                  data-srcset="images/mqtt-157.webp 1x, images/mqtt-314.webp 2x"
                  type="image/webp"
                  media="(max-width:959px)"
                />
                <source
                  data-srcset="images/mqtt-157.png 1x, images/mqtt-314.png 2x"
                  media="(max-width:959px)"
                />
                <source
                  data-srcset="images/mqtt-196-1.webp 1x, images/mqtt-392.webp 2x"
                  type="image/webp"
                  media="(max-width:1199px)"
                />
                <source
                  data-srcset="images/mqtt-196-1.png 1x, images/mqtt-392.png 2x"
                  media="(max-width:1199px)"
                />
                <source
                  data-srcset="images/mqtt-245.webp 1x"
                  type="image/webp"
                  media="(min-width:1200px)"
                />
                <source
                  data-srcset="images/mqtt-245.png 1x"
                  media="(min-width:1200px)"
                />
                <img
                  data-src="images/mqtt-65.png"
                  alt="MQTT server as a datalake"
                  className="js9 i5"
                />
              </picture>
            </a>
          </div>
          <div className="v1 ps48 s84 c58">
            <lottie-player
              src="images/70797-arrows.1fadef.json"
              background="transparent"
              speed={1}
              loop
              autoPlay
              className="lt1"
            ></lottie-player>
          </div>
          <div className="anim fadeInUp js12 v1 ps49 s85 c59">
            <a
              onclick="sparklePopup && sparklePopup.openPopup('popup2');return 0"
              style={{ cursor: "pointer" }}
              className="a1"
            >
              <picture className="i2 js265">
                <source
                  data-srcset="images/parser-37-1.webp 1x, images/parser-74-1.webp 2x"
                  type="image/webp"
                  media="(max-width:479px)"
                />
                <source
                  data-srcset="images/parser-37-1.png 1x, images/parser-74-1.png 2x"
                  media="(max-width:479px)"
                />
                <source
                  data-srcset="images/parser-55.webp 1x, images/parser-110.webp 2x"
                  type="image/webp"
                  media="(max-width:767px)"
                />
                <source
                  data-srcset="images/parser-55.png 1x, images/parser-110.png 2x"
                  media="(max-width:767px)"
                />
                <source
                  data-srcset="images/parser-89-1.webp 1x, images/parser-178-1.webp 2x"
                  type="image/webp"
                  media="(max-width:959px)"
                />
                <source
                  data-srcset="images/parser-89-1.png 1x, images/parser-178-1.png 2x"
                  media="(max-width:959px)"
                />
                <source
                  data-srcset="images/parser-112-2.webp 1x, images/parser-224-1.webp 2x"
                  type="image/webp"
                  media="(max-width:1199px)"
                />
                <source
                  data-srcset="images/parser-112-2.png 1x, images/parser-224-1.png 2x"
                  media="(max-width:1199px)"
                />
                <source
                  data-srcset="images/parser-140.webp 1x"
                  type="image/webp"
                  media="(min-width:1200px)"
                />
                <source
                  data-srcset="images/parser-140.png 1x"
                  media="(min-width:1200px)"
                />
                <img
                  data-src="images/parser-37-1.png"
                  alt="Parser "
                  className="js11 i6"
                />
              </picture>
            </a>
          </div>
          <div className="v1 ps50 s86 c60">
            <lottie-player
              src="images/70797-arrows.1fadef.json"
              background="transparent"
              speed={1}
              loop
              autoPlay
              className="lt2"
            ></lottie-player>
          </div>
          <div className="anim fadeInUp js14 v1 ps51 s87 c61">
            <a
              onclick="sparklePopup && sparklePopup.openPopup('popup3');return 0"
              style={{ cursor: "pointer" }}
              className="a1"
            >
              <picture className="i2 js266">
                <source
                  data-srcset="images/db-37-1.webp 1x, images/db-74-1.webp 2x"
                  type="image/webp"
                  media="(max-width:479px)"
                />
                <source
                  data-srcset="images/db-37-1.png 1x, images/db-74-1.png 2x"
                  media="(max-width:479px)"
                />
                <source
                  data-srcset="images/db-56.webp 1x, images/db-112.webp 2x"
                  type="image/webp"
                  media="(max-width:767px)"
                />
                <source
                  data-srcset="images/db-56.png 1x, images/db-112.png 2x"
                  media="(max-width:767px)"
                />
                <source
                  data-srcset="images/db-91-1.webp 1x, images/db-182-1.webp 2x"
                  type="image/webp"
                  media="(max-width:959px)"
                />
                <source
                  data-srcset="images/db-91-1.png 1x, images/db-182-1.png 2x"
                  media="(max-width:959px)"
                />
                <source
                  data-srcset="images/db-113-1.webp 1x, images/db-226-1.webp 2x"
                  type="image/webp"
                  media="(max-width:1199px)"
                />
                <source
                  data-srcset="images/db-113-1.png 1x, images/db-226-1.png 2x"
                  media="(max-width:1199px)"
                />
                <source
                  data-srcset="images/db-142.webp 1x"
                  type="image/webp"
                  media="(min-width:1200px)"
                />
                <source
                  data-srcset="images/db-142.png 1x"
                  media="(min-width:1200px)"
                />
                <img
                  data-src="images/db-37-1.png"
                  alt="Database"
                  className="js13 i7"
                />
              </picture>
            </a>
          </div>
          <div className="v1 ps52 s88 c62">
            <lottie-player
              src="images/70797-arrows.1fadef.json"
              background="transparent"
              speed={1}
              loop
              autoPlay
              className="lt3"
            ></lottie-player>
          </div>
          <div className="anim fadeInUp js16 v1 ps53 s89 c63">
            <a
              onclick="sparklePopup && sparklePopup.openPopup('popup4');return 0"
              style={{ cursor: "pointer" }}
              className="a1"
            >
              <picture className="i2 js267">
                <source
                  data-srcset="images/appp-63.webp 1x, images/appp-126.webp 2x"
                  type="image/webp"
                  media="(max-width:479px)"
                />
                <source
                  data-srcset="images/appp-63.png 1x, images/appp-126.png 2x"
                  media="(max-width:479px)"
                />
                <source
                  data-srcset="images/appp-95.webp 1x, images/appp-190.webp 2x"
                  type="image/webp"
                  media="(max-width:767px)"
                />
                <source
                  data-srcset="images/appp-95.png 1x, images/appp-190.png 2x"
                  media="(max-width:767px)"
                />
                <source
                  data-srcset="images/appp-151.webp 1x, images/appp-302.webp 2x"
                  type="image/webp"
                  media="(max-width:959px)"
                />
                <source
                  data-srcset="images/appp-151.png 1x, images/appp-302.png 2x"
                  media="(max-width:959px)"
                />
                <source
                  data-srcset="images/appp-189.webp 1x"
                  type="image/webp"
                  media="(max-width:1199px)"
                />
                <source
                  data-srcset="images/appp-189.png 1x"
                  media="(max-width:1199px)"
                />
                <source
                  data-srcset="images/appp-236.webp 1x"
                  type="image/webp"
                  media="(min-width:1200px)"
                />
                <source
                  data-srcset="images/appp-236.png 1x"
                  media="(min-width:1200px)"
                />
                <img data-src="images/appp-63.png" className="js15 i8" />
              </picture>
            </a>
          </div>
        </div>
        <div className="v1 ps54 s90 c2">
          <div className="anim flipInY js17 v1 ps5 s91 c64">
            <p className="p2 f11">Data Lake</p>
            <p className="p2 f12">
              For the data lake we use a very efficient MQTT server to gather
              all the raw data
            </p>
          </div>
          <div className="anim flipInY js18 v1 ps55 s92 c65">
            <p className="p2 f11">Parser</p>
            <p className="p2 f12">
              Raw data has to be parsed and tagged to be stored in a database.
              In the parser also data compression is working to drive lower
              resource of the system
            </p>
          </div>
          <div className="anim flipInY js19 v1 ps56 s93 c66">
            <p className="p2 f11">Data Base</p>
            <p className="p2 f12">
              We use InfluxDB for raw data storage, which is a time series
              database. Also MYSQL and other cloud base databases are used for
              several functions.
            </p>
          </div>
          <div className="anim flipInY js20 v1 ps57 s94 c67">
            <p className="p2 f11">SOX</p>
            <p className="p2 f12">Machine learned Result&nbsp;</p>
            <p className="p2 f12">
              Or human interactive graphs and figures are calculated from the
              data. Since all CLB can gather bigdata vertically and horizontally
              ML will give insights like accurate SOC, SOH, SOD.....
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <a name="work" className="v2 ps58 s40" />
  <div className="v1 ps59 s95 c68">
    <div className="c69 s69"></div>
    <div className="ps60 v2 s96">
      <div className="anim fadeInLeft js21 v1 ps5 s97 c70">
        <h1 className="p2 f13">Work On going</h1>
        <h2 className="p2 f14">Our best works</h2>
      </div>
    </div>
  </div>
  <div className="c71">
    <div className="ps1 v2 s98">
      <div className="v1 ps61 s99 c72">
        <div className="anim fadeInLeft js23 v1 ps62 s100 c46">
          <picture className="i2 js268">
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.21.16-pm-263-1.webp 1x, images/screen-shot-2021-08-04-at-12.21.16-pm-526-1.webp 2x"
              type="image/webp"
              media="(max-width:479px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.21.16-pm-263-1.jpg 1x, images/screen-shot-2021-08-04-at-12.21.16-pm-526-1.jpg 2x"
              media="(max-width:479px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.21.16-pm-395.webp 1x, images/screen-shot-2021-08-04-at-12.21.16-pm-790-1.webp 2x"
              type="image/webp"
              media="(max-width:767px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.21.16-pm-395.jpg 1x, images/screen-shot-2021-08-04-at-12.21.16-pm-790-1.jpg 2x"
              media="(max-width:767px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.21.16-pm-632-1.webp 1x, images/screen-shot-2021-08-04-at-12.21.16-pm-1264-1.webp 2x"
              type="image/webp"
              media="(max-width:959px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.21.16-pm-632-1.jpg 1x, images/screen-shot-2021-08-04-at-12.21.16-pm-1264-1.jpg 2x"
              media="(max-width:959px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.21.16-pm-790.webp 1x, images/screen-shot-2021-08-04-at-12.21.16-pm-1580.webp 2x"
              type="image/webp"
              media="(max-width:1199px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.21.16-pm-790.jpg 1x, images/screen-shot-2021-08-04-at-12.21.16-pm-1580.jpg 2x"
              media="(max-width:1199px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.21.16-pm-987.webp 1x, images/screen-shot-2021-08-04-at-12.21.16-pm-1974.webp 2x"
              type="image/webp"
              media="(min-width:1200px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.21.16-pm-987.jpg 1x, images/screen-shot-2021-08-04-at-12.21.16-pm-1974.jpg 2x"
              media="(min-width:1200px)"
            />
            <img
              data-src="images/screen-shot-2021-08-04-at-12.21.16-pm-263-1.jpg"
              alt="grafana "
              className="js22 i9"
            />
          </picture>
        </div>
        <div className="anim fadeInRight js25 v1 ps63 s101 c46">
          <picture className="i2 js269">
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.59.53-pm-263-1.webp 1x, images/screen-shot-2021-08-04-at-12.59.53-pm-526-1.webp 2x"
              type="image/webp"
              media="(max-width:479px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.59.53-pm-263-1.jpg 1x, images/screen-shot-2021-08-04-at-12.59.53-pm-526-1.jpg 2x"
              media="(max-width:479px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.59.53-pm-395.webp 1x, images/screen-shot-2021-08-04-at-12.59.53-pm-790-1.webp 2x"
              type="image/webp"
              media="(max-width:767px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.59.53-pm-395.jpg 1x, images/screen-shot-2021-08-04-at-12.59.53-pm-790-1.jpg 2x"
              media="(max-width:767px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.59.53-pm-632.webp 1x, images/screen-shot-2021-08-04-at-12.59.53-pm-1264.webp 2x"
              type="image/webp"
              media="(max-width:959px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.59.53-pm-632.jpg 1x, images/screen-shot-2021-08-04-at-12.59.53-pm-1264.jpg 2x"
              media="(max-width:959px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.59.53-pm-790.webp 1x, images/screen-shot-2021-08-04-at-12.59.53-pm-1580.webp 2x"
              type="image/webp"
              media="(max-width:1199px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.59.53-pm-790.jpg 1x, images/screen-shot-2021-08-04-at-12.59.53-pm-1580.jpg 2x"
              media="(max-width:1199px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.59.53-pm-987.webp 1x, images/screen-shot-2021-08-04-at-12.59.53-pm-1974.webp 2x"
              type="image/webp"
              media="(min-width:1200px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.59.53-pm-987.jpg 1x, images/screen-shot-2021-08-04-at-12.59.53-pm-1974.jpg 2x"
              media="(min-width:1200px)"
            />
            <img
              data-src="images/screen-shot-2021-08-04-at-12.59.53-pm-263-1.jpg"
              alt="python coding"
              className="js24 i10"
            />
          </picture>
        </div>
        <div className="anim fadeInLeft js27 v1 ps64 s102 c46">
          <picture className="i2 js270">
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.22.55-pm-263-1.webp 1x, images/screen-shot-2021-08-04-at-12.22.55-pm-526-1.webp 2x"
              type="image/webp"
              media="(max-width:479px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.22.55-pm-263-1.jpg 1x, images/screen-shot-2021-08-04-at-12.22.55-pm-526-1.jpg 2x"
              media="(max-width:479px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.22.55-pm-395.webp 1x, images/screen-shot-2021-08-04-at-12.22.55-pm-790-1.webp 2x"
              type="image/webp"
              media="(max-width:767px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.22.55-pm-395.jpg 1x, images/screen-shot-2021-08-04-at-12.22.55-pm-790-1.jpg 2x"
              media="(max-width:767px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.22.55-pm-632.webp 1x, images/screen-shot-2021-08-04-at-12.22.55-pm-1264.webp 2x"
              type="image/webp"
              media="(max-width:959px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.22.55-pm-632.jpg 1x, images/screen-shot-2021-08-04-at-12.22.55-pm-1264.jpg 2x"
              media="(max-width:959px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.22.55-pm-790.webp 1x, images/screen-shot-2021-08-04-at-12.22.55-pm-1580.webp 2x"
              type="image/webp"
              media="(max-width:1199px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.22.55-pm-790.jpg 1x, images/screen-shot-2021-08-04-at-12.22.55-pm-1580.jpg 2x"
              media="(max-width:1199px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.22.55-pm-987.webp 1x, images/screen-shot-2021-08-04-at-12.22.55-pm-1974.webp 2x"
              type="image/webp"
              media="(min-width:1200px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.22.55-pm-987.jpg 1x, images/screen-shot-2021-08-04-at-12.22.55-pm-1974.jpg 2x"
              media="(min-width:1200px)"
            />
            <img
              data-src="images/screen-shot-2021-08-04-at-12.22.55-pm-263-1.jpg"
              alt="influxdb"
              className="js26 i11"
            />
          </picture>
        </div>
        <div className="anim fadeInRight js29 v1 ps65 s103 c73">
          <picture className="i13 js271">
            <source
              data-srcset="images/ml3-262.webp 1x, images/ml3-524.webp 2x"
              type="image/webp"
              media="(max-width:479px)"
            />
            <source
              data-srcset="images/ml3-262.png 1x, images/ml3-524.png 2x"
              media="(max-width:479px)"
            />
            <source
              data-srcset="images/ml3-394.webp 1x, images/ml3-788.webp 2x"
              type="image/webp"
              media="(max-width:767px)"
            />
            <source
              data-srcset="images/ml3-394.png 1x, images/ml3-788.png 2x"
              media="(max-width:767px)"
            />
            <source
              data-srcset="images/ml3-630.webp 1x, images/ml3-1260.webp 2x"
              type="image/webp"
              media="(max-width:959px)"
            />
            <source
              data-srcset="images/ml3-630.png 1x, images/ml3-1260.png 2x"
              media="(max-width:959px)"
            />
            <source
              data-srcset="images/ml3-788-1.webp 1x, images/ml3-1576.webp 2x"
              type="image/webp"
              media="(max-width:1199px)"
            />
            <source
              data-srcset="images/ml3-788-1.png 1x, images/ml3-1576.png 2x"
              media="(max-width:1199px)"
            />
            <source
              data-srcset="images/ml3-985-1.webp 1x"
              type="image/webp"
              media="(min-width:1200px)"
            />
            <source
              data-srcset="images/ml3-985-1.png 1x"
              media="(min-width:1200px)"
            />
            <img data-src="images/ml3-262.png" className="js28 i12" />
          </picture>
        </div>
        <div className="v1 ps66 s104 c2">
          <div className="anim flipInY js31 v1 ps5 s105 c74">
            <picture className="i13 js272">
              <source
                data-srcset="images/ml2-127-1.webp 1x, images/ml2-254-1.webp 2x"
                type="image/webp"
                media="(max-width:479px)"
              />
              <source
                data-srcset="images/ml2-127-1.png 1x, images/ml2-254-1.png 2x"
                media="(max-width:479px)"
              />
              <source
                data-srcset="images/ml2-191.webp 1x, images/ml2-382.webp 2x"
                type="image/webp"
                media="(max-width:767px)"
              />
              <source
                data-srcset="images/ml2-191.png 1x, images/ml2-382.png 2x"
                media="(max-width:767px)"
              />
              <source
                data-srcset="images/ml2-305-1.webp 1x, images/ml2-610-1.webp 2x"
                type="image/webp"
                media="(max-width:959px)"
              />
              <source
                data-srcset="images/ml2-305-1.png 1x, images/ml2-610-1.png 2x"
                media="(max-width:959px)"
              />
              <source
                data-srcset="images/ml2-382-1.webp 1x, images/ml2-764.webp 2x"
                type="image/webp"
                media="(max-width:1199px)"
              />
              <source
                data-srcset="images/ml2-382-1.png 1x, images/ml2-764.png 2x"
                media="(max-width:1199px)"
              />
              <source
                data-srcset="images/ml2-477-1.webp 1x, images/ml2-954-1.webp 2x"
                type="image/webp"
                media="(min-width:1200px)"
              />
              <source
                data-srcset="images/ml2-477-1.png 1x, images/ml2-954-1.png 2x"
                media="(min-width:1200px)"
              />
              <img data-src="images/ml2-127-1.png" className="js30 i14" />
            </picture>
          </div>
          <div className="anim fadeInRight js33 v1 ps67 s106 c75">
            <picture className="i2 js273">
              <source
                data-srcset="images/screen-shot-2021-08-04-at-1.02.51-pm-128.webp 1x, images/screen-shot-2021-08-04-at-1.02.51-pm-256.webp 2x"
                type="image/webp"
                media="(max-width:479px)"
              />
              <source
                data-srcset="images/screen-shot-2021-08-04-at-1.02.51-pm-128.png 1x, images/screen-shot-2021-08-04-at-1.02.51-pm-256.png 2x"
                media="(max-width:479px)"
              />
              <source
                data-srcset="images/screen-shot-2021-08-04-at-1.02.51-pm-193.webp 1x, images/screen-shot-2021-08-04-at-1.02.51-pm-386.webp 2x"
                type="image/webp"
                media="(max-width:767px)"
              />
              <source
                data-srcset="images/screen-shot-2021-08-04-at-1.02.51-pm-193.png 1x, images/screen-shot-2021-08-04-at-1.02.51-pm-386.png 2x"
                media="(max-width:767px)"
              />
              <source
                data-srcset="images/screen-shot-2021-08-04-at-1.02.51-pm-308.webp 1x, images/screen-shot-2021-08-04-at-1.02.51-pm-616.webp 2x"
                type="image/webp"
                media="(max-width:959px)"
              />
              <source
                data-srcset="images/screen-shot-2021-08-04-at-1.02.51-pm-308.png 1x, images/screen-shot-2021-08-04-at-1.02.51-pm-616.png 2x"
                media="(max-width:959px)"
              />
              <source
                data-srcset="images/screen-shot-2021-08-04-at-1.02.51-pm-385.webp 1x, images/screen-shot-2021-08-04-at-1.02.51-pm-770.webp 2x"
                type="image/webp"
                media="(max-width:1199px)"
              />
              <source
                data-srcset="images/screen-shot-2021-08-04-at-1.02.51-pm-385.png 1x, images/screen-shot-2021-08-04-at-1.02.51-pm-770.png 2x"
                media="(max-width:1199px)"
              />
              <source
                data-srcset="images/screen-shot-2021-08-04-at-1.02.51-pm-481-1.webp 1x, images/screen-shot-2021-08-04-at-1.02.51-pm-962-1.webp 2x"
                type="image/webp"
                media="(min-width:1200px)"
              />
              <source
                data-srcset="images/screen-shot-2021-08-04-at-1.02.51-pm-481-1.png 1x, images/screen-shot-2021-08-04-at-1.02.51-pm-962-1.png 2x"
                media="(min-width:1200px)"
              />
              <img
                data-src="images/screen-shot-2021-08-04-at-1.02.51-pm-128.png"
                alt="ate"
                className="js32 i15"
              />
            </picture>
          </div>
        </div>
      </div>
    </div>
  </div>
  <a name="considerations" className="v2 ps68 s40" />
  <div className="v1 ps69 s68 c76">
    <div className="ps70 v2 s107">
      <div className="anim fadeInLeft js34 v1 ps71 s108 c77">
        <h1 className="p2 f15">Considerations</h1>
        <h2 className="p2 f16">
          To let the cloud system up and running on several conditions we've
          considered some important points to work the system out
        </h2>
      </div>
    </div>
  </div>
  <div className="ps72 v2 s109">
    <div className="v1 ps73 s110 c78">
      <h3 className="p2 f17">Scaleability</h3>
    </div>
  </div>
  <div className="c79">
    <div className="ps74 v2 s111">
      <div className="anim flipInY js36 v1 ps75 s112 c80">
        <picture className="i2 js274">
          <source
            data-srcset="images/pasted-image-179.webp 1x, images/pasted-image-358.webp 2x"
            type="image/webp"
            media="(max-width:479px)"
          />
          <source
            data-srcset="images/pasted-image-179.png 1x, images/pasted-image-358.png 2x"
            media="(max-width:479px)"
          />
          <source
            data-srcset="images/pasted-image-268-1.webp 1x, images/pasted-image-536-2.webp 2x"
            type="image/webp"
            media="(max-width:767px)"
          />
          <source
            data-srcset="images/pasted-image-268-1.png 1x, images/pasted-image-536-2.png 2x"
            media="(max-width:767px)"
          />
          <source
            data-srcset="images/pasted-image-428.webp 1x, images/pasted-image-856.webp 2x"
            type="image/webp"
            media="(max-width:959px)"
          />
          <source
            data-srcset="images/pasted-image-428.png 1x, images/pasted-image-856.png 2x"
            media="(max-width:959px)"
          />
          <source
            data-srcset="images/pasted-image-536-1.webp 1x, images/pasted-image-1072.webp 2x"
            type="image/webp"
            media="(max-width:1199px)"
          />
          <source
            data-srcset="images/pasted-image-536-1.png 1x, images/pasted-image-1072.png 2x"
            media="(max-width:1199px)"
          />
          <source
            data-srcset="images/pasted-image-670.webp 1x"
            type="image/webp"
            media="(min-width:1200px)"
          />
          <source
            data-srcset="images/pasted-image-670.png 1x"
            media="(min-width:1200px)"
          />
          <img data-src="images/pasted-image-179.png" className="js35 i16" />
        </picture>
      </div>
    </div>
  </div>
  <div className="ps76 v2 s113">
    <div className="v1 ps77 s114 c81">
      <h3 className="p2 f17">Security</h3>
    </div>
    <div className="v1 ps78 s115 c2">
      <div className="v1 ps5 s115 w1">
        <div className="v1 ps5 s116 c82">
          <picture className="i2 js275">
            <source
              data-srcset="images/pasted-image-304.webp 1x, images/pasted-image-608.webp 2x"
              type="image/webp"
              media="(max-width:479px)"
            />
            <source
              data-srcset="images/pasted-image-304.png 1x, images/pasted-image-608.png 2x"
              media="(max-width:479px)"
            />
            <source
              data-srcset="images/pasted-image-462.webp 1x, images/pasted-image-924.webp 2x"
              type="image/webp"
              media="(max-width:767px)"
            />
            <source
              data-srcset="images/pasted-image-462.png 1x, images/pasted-image-924.png 2x"
              media="(max-width:767px)"
            />
            <source
              data-srcset="images/pasted-image-738.webp 1x"
              type="image/webp"
              media="(max-width:959px)"
            />
            <source
              data-srcset="images/pasted-image-738.png 1x"
              media="(max-width:959px)"
            />
            <source
              data-srcset="images/pasted-image-923.webp 1x"
              type="image/webp"
              media="(max-width:1199px)"
            />
            <source
              data-srcset="images/pasted-image-923.png 1x"
              media="(max-width:1199px)"
            />
            <source
              data-srcset="images/pasted-image-1153.webp 1x"
              type="image/webp"
              media="(min-width:1200px)"
            />
            <source
              data-srcset="images/pasted-image-1153.png 1x"
              media="(min-width:1200px)"
            />
            <img data-src="images/pasted-image-304.png" className="js37 i17" />
          </picture>
        </div>
        <div className="v1 ps79 s117 c83">
          <a
            onclick="sparklePopup && sparklePopup.openPopup('popup5');return 0"
            style={{ cursor: "pointer" }}
            className="a1"
          >
            <picture className="i2 js276">
              <source
                data-srcset="images/vysokkgpelu-27.webp 1x, images/vysokkgpelu-54.webp 2x"
                type="image/webp"
                media="(max-width:479px)"
              />
              <source
                data-srcset="images/vysokkgpelu-27.jpg 1x, images/vysokkgpelu-54.jpg 2x"
                media="(max-width:479px)"
              />
              <source
                data-srcset="images/vysokkgpelu-41.webp 1x, images/vysokkgpelu-82.webp 2x"
                type="image/webp"
                media="(max-width:767px)"
              />
              <source
                data-srcset="images/vysokkgpelu-41.jpg 1x, images/vysokkgpelu-82.jpg 2x"
                media="(max-width:767px)"
              />
              <source
                data-srcset="images/vysokkgpelu-66-1.webp 1x, images/vysokkgpelu-132-1.webp 2x"
                type="image/webp"
                media="(max-width:959px)"
              />
              <source
                data-srcset="images/vysokkgpelu-66-1.jpg 1x, images/vysokkgpelu-132-1.jpg 2x"
                media="(max-width:959px)"
              />
              <source
                data-srcset="images/vysokkgpelu-82-1.webp 1x, images/vysokkgpelu-164.webp 2x"
                type="image/webp"
                media="(max-width:1199px)"
              />
              <source
                data-srcset="images/vysokkgpelu-82-1.jpg 1x, images/vysokkgpelu-164.jpg 2x"
                media="(max-width:1199px)"
              />
              <source
                data-srcset="images/vysokkgpelu-102.webp 1x, images/vysokkgpelu-204.webp 2x"
                type="image/webp"
                media="(min-width:1200px)"
              />
              <source
                data-srcset="images/vysokkgpelu-102.jpg 1x, images/vysokkgpelu-204.jpg 2x"
                media="(min-width:1200px)"
              />
              <img data-src="images/vysokkgpelu-27.jpg" className="js38 i18" />
            </picture>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div className="v1 ps80 s118 c84">
    <div className="c85 s69"></div>
    <a name="contact" className="v1 ps5 s40" />
    <div className="ps81 v2 s119">
      <div className="anim fadeInLeft js39 v1 ps22 s120 c86">
        <h1 className="p2 f13">Links</h1>
      </div>
    </div>
  </div>
  <div className="ps82 v2 s121">
    <div className="v1 ps83 s122 c87">
      <div className="v1 ps84 s123 c2">
        <div className="v1 ps5 s124 c88">
          <p className="p2">
            <span className="f18">
              <a
                href="https://ucsd.clbcloud.net/"
                target="_blank"
                rel="noopener"
              >
                UCSD Project
              </a>
            </span>
          </p>
        </div>
        <div className="v1 ps85 s125 c89">
          <p className="p2">
            <span className="f18">
              <a
                href="https://ucsd.clbcloud.net/"
                target="_blank"
                rel="noopener"
              >
                AWS
              </a>
            </span>
          </p>
        </div>
      </div>
      <div className="v1 ps86 s126 c2">
        <div className="v1 ps5 s127 c90">
          <p className="p2">
            <span className="f18">
              <a
                href="http://grafana.clbcloud.com"
                target="_blank"
                rel="noopener"
              >
                Visualization
              </a>
            </span>
          </p>
        </div>
        <div className="v1 ps85 s128 c91">
          <p className="p2">
            <span className="f18">
              <a
                href="https://us-west-2.admin.amplifyapp.com/admin/login?appId=d2vwn0y5qpubx7&code=67977a2c-04c4-4c74-92fa-ba3c53daf52e&sessionId=8207e3f6-f215-48a4-a324-5b46bda3a9a3&backendEnvironmentName=dev"
                target="_blank"
                rel="noopener"
              >
                AdminUI
              </a>
            </span>
          </p>
        </div>
      </div>
      <div className="v1 ps87 s129 c92">
        <p className="p2">
          <span className="f18">
            <a
              href="https://cloud.h2o.ai/login?referer=%2F"
              target="_blank"
              rel="noopener"
            >
              H20
            </a>
          </span>
        </p>
      </div>
      <div className="v1 ps88 s129 c93">
        <p className="p2">
          <span className="f18">
            <a
              href="https://bitbucket.org/dashboard/overview"
              target="_blank"
              rel="noopener"
            >
              Bitbucket
            </a>
          </span>
        </p>
      </div>
      <div className="v1 ps89 s130 c94">
        <p className="p2">
          <span className="f18">
            <a
              href="https://nodered.clbcloud.net/"
              target="_blank"
              rel="noopener"
            >
              Node-Red
            </a>
          </span>
        </p>
      </div>
      <div className="v1 ps90 s129 c95">
        <p className="p2">
          <span className="f18">
            <a
              href="https://us-west-2-1.aws.cloud2.influxdata.com/orgs/acbf39d1ab395662/data-explorer"
              target="_blank"
              rel="noopener"
            >
              InfluxDB
            </a>
          </span>
        </p>
      </div>
    </div>
  </div>
  <div
    id="popup5"
    style={{ transform: "translate3d(-999999px,0,0)" }}
    data-popup-type={1}
    className="popup ps91"
    onclick="sparklePopup.closePopup('popup5')"
  >
    <div className="ps92">
      <div
        onclick="event.stopPropagation()"
        className="modalpopup v7 ps93 s131 c96"
      >
        <div className="c97 s69"></div>
        <div className="c98">
          <div className="v1 ps94 s132 c99">
            <a
              onclick="sparklePopup && sparklePopup.closePopup('popup5');return 0"
              style={{ cursor: "pointer" }}
              className="f19 btn2 v6 s133"
            >
              &nbsp;
            </a>
          </div>
          <div className="v1 ps95 s134 c100">
            <p className="p2 f10">Two techniques are used for security</p>
            <p className="p2 f10">
              1. Data : ID/Password is required to login for subscription of the
              transaction data
            </p>
          </div>
          <div className="v1 ps96 s135 c101">
            <picture className="i2 js277">
              <source
                data-srcset="images/pasted-image-199.webp 1x, images/pasted-image-398.webp 2x"
                type="image/webp"
                media="(max-width:479px)"
              />
              <source
                data-srcset="images/pasted-image-199.jpg 1x, images/pasted-image-398.jpg 2x"
                media="(max-width:479px)"
              />
              <source
                data-srcset="images/pasted-image-299.webp 1x, images/pasted-image-598.webp 2x"
                type="image/webp"
                media="(max-width:767px)"
              />
              <source
                data-srcset="images/pasted-image-299.jpg 1x, images/pasted-image-598.jpg 2x"
                media="(max-width:767px)"
              />
              <source
                data-srcset="images/pasted-image-479.webp 1x, images/pasted-image-958.webp 2x"
                type="image/webp"
                media="(max-width:959px)"
              />
              <source
                data-srcset="images/pasted-image-479.jpg 1x, images/pasted-image-958.jpg 2x"
                media="(max-width:959px)"
              />
              <source
                data-srcset="images/pasted-image-598-1.webp 1x, images/pasted-image-1196.webp 2x"
                type="image/webp"
                media="(max-width:1199px)"
              />
              <source
                data-srcset="images/pasted-image-598-1.jpg 1x, images/pasted-image-1196.jpg 2x"
                media="(max-width:1199px)"
              />
              <source
                data-srcset="images/pasted-image-748.webp 1x, images/pasted-image-1496.webp 2x"
                type="image/webp"
                media="(min-width:1200px)"
              />
              <source
                data-srcset="images/pasted-image-748.jpg 1x, images/pasted-image-1496.jpg 2x"
                media="(min-width:1200px)"
              />
              <img
                data-src="images/pasted-image-199.jpg"
                className="js40 i19"
              />
            </picture>
          </div>
          <div className="v1 ps97 s136 c102">
            <p className="p2 f10">
              2. IP / port : IP address is under a loadbalencer thru a DNS which
              cannot be identified.
            </p>
            <p className="p2 f10">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Also
              Port is scrambled and forwarded thru the load balancer.
            </p>
          </div>
          <div className="v1 ps98 s137 c103">
            <picture className="i2 js278">
              <source
                data-srcset="images/pasted-image-140.webp 1x, images/pasted-image-280.webp 2x"
                type="image/webp"
                media="(max-width:479px)"
              />
              <source
                data-srcset="images/pasted-image-140.png 1x, images/pasted-image-280.png 2x"
                media="(max-width:479px)"
              />
              <source
                data-srcset="images/pasted-image-212.webp 1x, images/pasted-image-424.webp 2x"
                type="image/webp"
                media="(max-width:767px)"
              />
              <source
                data-srcset="images/pasted-image-212.png 1x, images/pasted-image-424.png 2x"
                media="(max-width:767px)"
              />
              <source
                data-srcset="images/pasted-image-339.webp 1x, images/pasted-image-678.webp 2x"
                type="image/webp"
                media="(max-width:959px)"
              />
              <source
                data-srcset="images/pasted-image-339.png 1x, images/pasted-image-678.png 2x"
                media="(max-width:959px)"
              />
              <source
                data-srcset="images/pasted-image-424-1.webp 1x, images/pasted-image-848.webp 2x"
                type="image/webp"
                media="(max-width:1199px)"
              />
              <source
                data-srcset="images/pasted-image-424-1.png 1x, images/pasted-image-848.png 2x"
                media="(max-width:1199px)"
              />
              <source
                data-srcset="images/pasted-image-530.webp 1x, images/pasted-image-1060.webp 2x"
                type="image/webp"
                media="(min-width:1200px)"
              />
              <source
                data-srcset="images/pasted-image-530.png 1x, images/pasted-image-1060.png 2x"
                media="(min-width:1200px)"
              />
              <img
                data-src="images/pasted-image-140.png"
                className="js41 i20"
              />
            </picture>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    id="popup6"
    style={{ transform: "translate3d(-999999px,0,0)" }}
    data-popup-type={1}
    className="popup ps91"
    onclick="sparklePopup.closePopup('popup6')"
  >
    <div className="ps92">
      <div
        onclick="event.stopPropagation()"
        className="modalpopup v7 ps99 s138 c104"
      >
        <div className="c105">
          <div className="v1 ps100 s139 c106">
            <a
              onclick="sparklePopup && sparklePopup.closePopup('popup6');return 0"
              style={{ cursor: "pointer" }}
              className="f20 btn3 v6 s140"
            >
              &nbsp;
            </a>
          </div>
          <div className="v1 ps5 s141 c107">
            <p className="p2 f21">Battery pack - IP and port security</p>
            <p className="p2 f21">MQTT server - ID &amp; password, port</p>
            <p className="p2 f21">
              Parser - ip , https, loadbalencing , ID &amp; password
            </p>
            <p className="p2 f21">
              DB - ip , key token, tls , ID &amp; password
            </p>
            <p className="p2 f21">
              Application ( Grafana) - https, ip , loadbalencing, ID &amp;
              password
            </p>
            <p className="p2 f21">
              s3 - AWS, token,&nbsp;&nbsp;ID &amp; password
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    id="popup3"
    style={{ transform: "translate3d(-999999px,0,0)" }}
    data-popup-type={1}
    className="popup ps91"
    onclick="sparklePopup.closePopup('popup3')"
  >
    <div className="ps92">
      <div
        className="animm fadeInUp js42 modalpopup v7 ps101 s142 c108"
        onclick="event.stopPropagation()"
      >
        <div className="c109 s69"></div>
        <div className="v1 ps102 s139 c110">
          <a
            onclick="sparklePopup && sparklePopup.closePopup('popup3');return 0"
            style={{ cursor: "pointer" }}
            className="f20 btn4 v6 s140"
          >
            &nbsp;
          </a>
        </div>
        <div className="v1 ps103 s143 c111">
          <p className="p2 f10">DataBase</p>
        </div>
        <div className="v1 ps104 s144 c112">
          <picture className="i2 js279">
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.22.55-pm-229.webp 1x, images/screen-shot-2021-08-04-at-12.22.55-pm-458.webp 2x"
              type="image/webp"
              media="(max-width:479px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.22.55-pm-229.jpg 1x, images/screen-shot-2021-08-04-at-12.22.55-pm-458.jpg 2x"
              media="(max-width:479px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.22.55-pm-343.webp 1x, images/screen-shot-2021-08-04-at-12.22.55-pm-686.webp 2x"
              type="image/webp"
              media="(max-width:767px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.22.55-pm-343.jpg 1x, images/screen-shot-2021-08-04-at-12.22.55-pm-686.jpg 2x"
              media="(max-width:767px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.22.55-pm-548-1.webp 1x, images/screen-shot-2021-08-04-at-12.22.55-pm-1096-1.webp 2x"
              type="image/webp"
              media="(max-width:959px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.22.55-pm-548-1.jpg 1x, images/screen-shot-2021-08-04-at-12.22.55-pm-1096-1.jpg 2x"
              media="(max-width:959px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.22.55-pm-686-1.webp 1x, images/screen-shot-2021-08-04-at-12.22.55-pm-1372.webp 2x"
              type="image/webp"
              media="(max-width:1199px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.22.55-pm-686-1.jpg 1x, images/screen-shot-2021-08-04-at-12.22.55-pm-1372.jpg 2x"
              media="(max-width:1199px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.22.55-pm-858.webp 1x, images/screen-shot-2021-08-04-at-12.22.55-pm-1716.webp 2x"
              type="image/webp"
              media="(min-width:1200px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.22.55-pm-858.jpg 1x, images/screen-shot-2021-08-04-at-12.22.55-pm-1716.jpg 2x"
              media="(min-width:1200px)"
            />
            <img
              data-src="images/screen-shot-2021-08-04-at-12.22.55-pm-229.jpg"
              className="js43 i21"
            />
          </picture>
        </div>
        <div className="v1 ps105 s145 c113">
          <picture className="i2 js280">
            <source
              data-srcset="images/raww-227.webp 1x, images/raww-454.webp 2x"
              type="image/webp"
              media="(max-width:479px)"
            />
            <source
              data-srcset="images/raww-227.png 1x, images/raww-454.png 2x"
              media="(max-width:479px)"
            />
            <source
              data-srcset="images/raww-340.webp 1x, images/raww-680.webp 2x"
              type="image/webp"
              media="(max-width:767px)"
            />
            <source
              data-srcset="images/raww-340.png 1x, images/raww-680.png 2x"
              media="(max-width:767px)"
            />
            <source
              data-srcset="images/raww-544.webp 1x, images/raww-1088.webp 2x"
              type="image/webp"
              media="(max-width:959px)"
            />
            <source
              data-srcset="images/raww-544.png 1x, images/raww-1088.png 2x"
              media="(max-width:959px)"
            />
            <source
              data-srcset="images/raww-680-1.webp 1x, images/raww-1360.webp 2x"
              type="image/webp"
              media="(max-width:1199px)"
            />
            <source
              data-srcset="images/raww-680-1.png 1x, images/raww-1360.png 2x"
              media="(max-width:1199px)"
            />
            <source
              data-srcset="images/raww-850.webp 1x"
              type="image/webp"
              media="(min-width:1200px)"
            />
            <source
              data-srcset="images/raww-850.png 1x"
              media="(min-width:1200px)"
            />
            <img data-src="images/raww-227.png" className="js44 i22" />
          </picture>
        </div>
        <div className="v1 ps106 s146 c114">
          <picture className="i2 js281">
            <source
              data-srcset="images/training-data-228.webp 1x, images/training-data-456.webp 2x"
              type="image/webp"
              media="(max-width:479px)"
            />
            <source
              data-srcset="images/training-data-228.png 1x, images/training-data-456.png 2x"
              media="(max-width:479px)"
            />
            <source
              data-srcset="images/training-data-341.webp 1x, images/training-data-682.webp 2x"
              type="image/webp"
              media="(max-width:767px)"
            />
            <source
              data-srcset="images/training-data-341.png 1x, images/training-data-682.png 2x"
              media="(max-width:767px)"
            />
            <source
              data-srcset="images/training-data-546.webp 1x, images/training-data-1092.webp 2x"
              type="image/webp"
              media="(max-width:959px)"
            />
            <source
              data-srcset="images/training-data-546.png 1x, images/training-data-1092.png 2x"
              media="(max-width:959px)"
            />
            <source
              data-srcset="images/training-data-682-1.webp 1x, images/training-data-1364.webp 2x"
              type="image/webp"
              media="(max-width:1199px)"
            />
            <source
              data-srcset="images/training-data-682-1.png 1x, images/training-data-1364.png 2x"
              media="(max-width:1199px)"
            />
            <source
              data-srcset="images/training-data-852.webp 1x, images/training-data-1704.webp 2x"
              type="image/webp"
              media="(min-width:1200px)"
            />
            <source
              data-srcset="images/training-data-852.png 1x, images/training-data-1704.png 2x"
              media="(min-width:1200px)"
            />
            <img data-src="images/training-data-228.png" className="js45 i23" />
          </picture>
        </div>
      </div>
    </div>
  </div>
  <div
    id="popup4"
    style={{ transform: "translate3d(-999999px,0,0)" }}
    data-popup-type={1}
    className="popup ps91"
    onclick="sparklePopup.closePopup('popup4')"
  >
    <div className="ps92">
      <div
        className="animm fadeInUp js46 modalpopup v7 ps107 s147 c115"
        onclick="event.stopPropagation()"
      >
        <div className="c116 s69"></div>
        <div className="v1 ps108 s148 c117">
          <p className="p2">
            <span className="f22">
              <a
                href="https://grafana.clbcloud.com"
                target="_blank"
                rel="noopener"
                onclick="sparklePopup.openClosePopup('popup4');return 0"
                style={{ cursor: "pointer" }}
              >
                Visualization with Grafana
              </a>
            </span>
          </p>
        </div>
        <div className="v1 ps109 s149 c118">
          <a
            onclick="sparklePopup && sparklePopup.closePopup('popup4');return 0"
            style={{ cursor: "pointer" }}
            className="f23 btn5 v6 s150"
          >
            &nbsp;
          </a>
        </div>
        <div className="v1 ps110 s151 c119">
          <picture className="i2 js282">
            <source
              data-srcset="images/pasted-image-220.webp 1x, images/pasted-image-440.webp 2x"
              type="image/webp"
              media="(max-width:479px)"
            />
            <source
              data-srcset="images/pasted-image-220.png 1x, images/pasted-image-440.png 2x"
              media="(max-width:479px)"
            />
            <source
              data-srcset="images/pasted-image-329.webp 1x, images/pasted-image-658-1.webp 2x"
              type="image/webp"
              media="(max-width:767px)"
            />
            <source
              data-srcset="images/pasted-image-329.png 1x, images/pasted-image-658-1.png 2x"
              media="(max-width:767px)"
            />
            <source
              data-srcset="images/pasted-image-526-1.webp 1x, images/pasted-image-1052-1.webp 2x"
              type="image/webp"
              media="(max-width:959px)"
            />
            <source
              data-srcset="images/pasted-image-526-1.png 1x, images/pasted-image-1052-1.png 2x"
              media="(max-width:959px)"
            />
            <source
              data-srcset="images/pasted-image-658.webp 1x, images/pasted-image-1316.webp 2x"
              type="image/webp"
              media="(max-width:1199px)"
            />
            <source
              data-srcset="images/pasted-image-658.png 1x, images/pasted-image-1316.png 2x"
              media="(max-width:1199px)"
            />
            <source
              data-srcset="images/pasted-image-822.webp 1x, images/pasted-image-1644.webp 2x"
              type="image/webp"
              media="(min-width:1200px)"
            />
            <source
              data-srcset="images/pasted-image-822.png 1x, images/pasted-image-1644.png 2x"
              media="(min-width:1200px)"
            />
            <img data-src="images/pasted-image-220.png" className="js47 i24" />
          </picture>
        </div>
        <div className="v1 ps111 s152 c120">
          <p className="p2 f10">ML&nbsp;</p>
        </div>
        <div className="v1 ps112 s153 c121">
          <picture className="i2 js283">
            <source
              data-srcset="images/ml3-159.webp 1x, images/ml3-318.webp 2x"
              type="image/webp"
              media="(max-width:479px)"
            />
            <source
              data-srcset="images/ml3-159.png 1x, images/ml3-318.png 2x"
              media="(max-width:479px)"
            />
            <source
              data-srcset="images/ml3-240.webp 1x, images/ml3-480.webp 2x"
              type="image/webp"
              media="(max-width:767px)"
            />
            <source
              data-srcset="images/ml3-240.png 1x, images/ml3-480.png 2x"
              media="(max-width:767px)"
            />
            <source
              data-srcset="images/ml3-382.webp 1x, images/ml3-764.webp 2x"
              type="image/webp"
              media="(max-width:959px)"
            />
            <source
              data-srcset="images/ml3-382.png 1x, images/ml3-764.png 2x"
              media="(max-width:959px)"
            />
            <source
              data-srcset="images/ml3-480-1.webp 1x, images/ml3-960.webp 2x"
              type="image/webp"
              media="(max-width:1199px)"
            />
            <source
              data-srcset="images/ml3-480-1.png 1x, images/ml3-960.png 2x"
              media="(max-width:1199px)"
            />
            <source
              data-srcset="images/ml3-600.webp 1x, images/ml3-1200.webp 2x"
              type="image/webp"
              media="(min-width:1200px)"
            />
            <source
              data-srcset="images/ml3-600.png 1x, images/ml3-1200.png 2x"
              media="(min-width:1200px)"
            />
            <img data-src="images/ml3-159.png" className="js48 i25" />
          </picture>
        </div>
        <div className="v1 ps113 s154 c122">
          <picture className="i2 js284">
            <source
              data-srcset="images/ml2-160.webp 1x, images/ml2-320.webp 2x"
              type="image/webp"
              media="(max-width:479px)"
            />
            <source
              data-srcset="images/ml2-160.png 1x, images/ml2-320.png 2x"
              media="(max-width:479px)"
            />
            <source
              data-srcset="images/ml2-240.webp 1x, images/ml2-480.webp 2x"
              type="image/webp"
              media="(max-width:767px)"
            />
            <source
              data-srcset="images/ml2-240.png 1x, images/ml2-480.png 2x"
              media="(max-width:767px)"
            />
            <source
              data-srcset="images/ml2-384-1.webp 1x, images/ml2-768-1.webp 2x"
              type="image/webp"
              media="(max-width:959px)"
            />
            <source
              data-srcset="images/ml2-384-1.png 1x, images/ml2-768-1.png 2x"
              media="(max-width:959px)"
            />
            <source
              data-srcset="images/ml2-480-1.webp 1x, images/ml2-960.webp 2x"
              type="image/webp"
              media="(max-width:1199px)"
            />
            <source
              data-srcset="images/ml2-480-1.png 1x, images/ml2-960.png 2x"
              media="(max-width:1199px)"
            />
            <source
              data-srcset="images/ml2-600.webp 1x, images/ml2-1200.webp 2x"
              type="image/webp"
              media="(min-width:1200px)"
            />
            <source
              data-srcset="images/ml2-600.png 1x, images/ml2-1200.png 2x"
              media="(min-width:1200px)"
            />
            <img data-src="images/ml2-160.png" className="js49 i26" />
          </picture>
        </div>
      </div>
    </div>
  </div>
  <div
    id="popup2"
    style={{ transform: "translate3d(-999999px,0,0)" }}
    data-popup-type={1}
    className="popup ps91"
    onclick="sparklePopup.closePopup('popup2')"
  >
    <div className="ps92">
      <div
        className="animm fadeInUp js50 modalpopup v7 ps114 s155 c123"
        onclick="event.stopPropagation()"
      >
        <div className="bd c124">
          <div className="c125 s69"></div>
        </div>
        <div className="v1 ps115 s156 c126">
          <p className="p2">
            <span className="f24">
              <a
                href="https://nodered.clbcloud.net/"
                target="_blank"
                rel="noopener"
                onclick="sparklePopup.openClosePopup('popup2');return 0"
                style={{ cursor: "pointer" }}
              >
                Node-red
              </a>
            </span>
            <span className="f25">&nbsp;</span>
          </p>
        </div>
        <div className="v1 ps116 s139 c127">
          <a
            onclick="sparklePopup && sparklePopup.closePopup('popup2');return 0"
            style={{ cursor: "pointer" }}
            className="f20 btn6 v6 s140"
          >
            &nbsp;
          </a>
        </div>
        <div className="v1 ps117 s157 c128">
          <picture className="i2 js285">
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.51.16-pm-234.webp 1x, images/screen-shot-2021-08-04-at-12.51.16-pm-468.webp 2x"
              type="image/webp"
              media="(max-width:479px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.51.16-pm-234.png 1x, images/screen-shot-2021-08-04-at-12.51.16-pm-468.png 2x"
              media="(max-width:479px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.51.16-pm-351.webp 1x, images/screen-shot-2021-08-04-at-12.51.16-pm-702.webp 2x"
              type="image/webp"
              media="(max-width:767px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.51.16-pm-351.png 1x, images/screen-shot-2021-08-04-at-12.51.16-pm-702.png 2x"
              media="(max-width:767px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.51.16-pm-562.webp 1x, images/screen-shot-2021-08-04-at-12.51.16-pm-1124.webp 2x"
              type="image/webp"
              media="(max-width:959px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.51.16-pm-562.png 1x, images/screen-shot-2021-08-04-at-12.51.16-pm-1124.png 2x"
              media="(max-width:959px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.51.16-pm-702-1.webp 1x, images/screen-shot-2021-08-04-at-12.51.16-pm-1404.webp 2x"
              type="image/webp"
              media="(max-width:1199px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.51.16-pm-702-1.png 1x, images/screen-shot-2021-08-04-at-12.51.16-pm-1404.png 2x"
              media="(max-width:1199px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.51.16-pm-878.webp 1x, images/screen-shot-2021-08-04-at-12.51.16-pm-1756.webp 2x"
              type="image/webp"
              media="(min-width:1200px)"
            />
            <source
              data-srcset="images/screen-shot-2021-08-04-at-12.51.16-pm-878.png 1x, images/screen-shot-2021-08-04-at-12.51.16-pm-1756.png 2x"
              media="(min-width:1200px)"
            />
            <img
              data-src="images/screen-shot-2021-08-04-at-12.51.16-pm-234.png"
              className="js51 i27"
            />
          </picture>
        </div>
      </div>
    </div>
  </div>
  <div
    id="popup1"
    style={{ transform: "translate3d(-999999px,0,0)" }}
    data-popup-type={1}
    className="popup ps91"
    onclick="sparklePopup.closePopup('popup1')"
  >
    <div className="ps92">
      <div
        className="animm fadeInUp js52 modalpopup v7 ps118 s158 c129"
        onclick="event.stopPropagation()"
      >
        <div className="c130 s69"></div>
        <div className="v1 ps119 s159 c131">
          <a
            onclick="sparklePopup && sparklePopup.closePopup('popup1');return 0"
            style={{ cursor: "pointer" }}
            className="f26 btn7 v6 s160"
          >
            &nbsp;
          </a>
        </div>
        <div className="v1 ps120 s161 c132">
          <p className="p2">
            <span className="f27">
              <a
                href="https://nodered.clbcloud.net/ui/"
                target="_blank"
                rel="noopener"
                onclick="sparklePopup.openClosePopup('popup1');return 0"
                style={{ cursor: "pointer" }}
              >
                MQTT flow
              </a>
            </span>
          </p>
        </div>
        <div className="v1 ps121 s162 c133">
          <div className="wrapper11">
            <iframe
              frameBorder={0}
              src="https://nodered.clbcloud.net/ui/#!/1?socketid=j6Wkpmd53MR03jYwAAi6"
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="c134"></div>
  <div className="menu-device" />
</>


        );

    }

}



export default App;