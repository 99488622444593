import logo from './logo.svg';
import './App.css';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import {AmplifySignOut, withAuthenticator } from '@aws-amplify/ui-react'
import Home from './pages/home'

Amplify.configure(awsconfig)

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* < Home /> */}
        <iframe title="CLBcloud main" src="./home.html" height="2000px" width="100%" frameborder="0"></iframe>  
      </header>
      <div class="row">
          <div class="column" ><img src="ee_logo.jpg" height="43"/> </div>
          <div class="column"> <AmplifySignOut /></div>
        </div>
    </div>
  );
}

export default withAuthenticator(App);
